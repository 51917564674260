<template>
    <div :id="refGrafica" class="row mx-0 py-3 bg-white br-8 mb-3">
        <div class="col-6 f-18 text-general f-500">
            {{ titulo }}
        </div>
        <div class="col-6 f-18 text-general f-500 text-right">
            Últimos 12 meses
            <el-popover
            placement="right"
            width="250"
            :disabled="desactivarPopover"
            trigger="hover"
            popper-class="p-2 br-8"
            >
                <p style="word-break:break-word;">
                    {{ descripcionGrafica }}
                </p>
                <i slot="reference" class="icon-info-circled-alt f-18 text-general cr-pointer" />
            </el-popover>
        </div>
        <div class="col-12 my-3">
            <echart :options="ingresoImpuesto" />
        </div>
        <!-- Partial -->
        <!-- <modal ref="modalInfoGrafica" :titulo="titulo" no-aceptar no-cancelar>
            <div class="row mx-0">
                <div class="col-12 text-general2 text-center f-16">
                    La asignación del IVA es una parte importante de la contabilidad de las empresas que pagan impuestos de conformidad con el sistema impositivo básico.
                </div>
            </div>
        </modal> -->
    </div>
</template>

<script>
let colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general')
export default {
    props: {
        titulo: {
            type: String,
            default: ''
        },
        descripcionGrafica: {
            type: String,
            default: ''
        },
        desactivarPopover: {
            type: Boolean,
            default: false
        },
        refGrafica: {
            type: String,
            default: ''
        },
        source: {
            type: Array,
            default: () => []
        },
        formatPorcentaje: {
            type:Boolean,
            default: true
        }
    },
    computed: {
        ingresoImpuesto(){
            return {
                tooltip: {
                    trigger: 'axis',
                    formatter: (params) => {
                        let { value } = params[0]
                        const valor = this.formatPorcentaje ? this.separadorNumero(value.valor):this.truncarNumero(value.valor, 1)
                        return `Mes: ${value.mes}</br>
                        <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#DBDBDB;"></span>
                        Valor: ${valor}</br>
                        <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#FF1720;"></span>
                        Variacion ${this.agregarSeparadoresNumero(Math.round(value.variacion))} %`
                    }
                },
                dataset:{
                    dimensions:['mes', 'valor', 'variacion'],
                    source: this.source,
                },
                legend: {
                    textStyle: {
                        color: '#707070',
                    },
                    data: ['Variacion', 'Valor']
                },
                grid: {
                    bottom: '1%',
                },
                xAxis: {
                    type: 'category',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#F6F6F6',
                            width: 2
                        }
                    },
                    axisLabel: {
                        show: false,
                        color: '#707070'
                    },
                },
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        name: 'Variación',
                        position: 'right',
                        alignTicks: true,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: colorGeneral,
                            }
                        },
                        /* axisLabel: {
                            formatter: '{value} °C'
                        } */
                    }
                ],
                series: [
                    {
                        name: 'Valor',
                        type: 'bar',
                        barMaxWidth: 30,
                        itemStyle: {
                            normal: {
                                color: '#DBDBDB',
                            },
                        },
                    },
                    {
                        name: 'Variacion',
                        symbol: 'circle',
                        type: 'line',
                        yAxisIndex: 1,
                        itemStyle: {
                            normal: {
                                color: colorGeneral,
                            },
                        },
                    },
                ]
            }
        }
    },
    watch: {
        /* refGrafica(newValue, oldValue){
            console.log(newValue, oldValue);
        } */
    },
    methods: {
        infoGrafica(){
            this.$refs.modalInfoGrafica.toggle();
        }
    },
}
</script>